import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-boost';
import {
  ACTIVATE_DISPLAY_CATEGORY_MUTATION,
  CONNECT_BETWEEN_CATEGORY,
  CREATE_DISPLAY_CATEGORY_MUTATION,
  INACTIVATE_DISPLAY_CATEGORY_MUTATION,
  MOVE_DISPLAY_CATEGORY_MUTATION,
} from '@/domain/category/display/queries/mutation';
import {
  CategoryMapParam,
  DisplayCategoryConnection,
  DisplayCategoryCreateParam,
  MoveDisplayCategoryParam,
} from '@/domain/category/display/model/DisplayCategoryGraphqltype';
import { CategoryRequestPram } from '@/domain/category/model/CategoryRequestPram';
import { DISPLAY_CATEGORY_LIST_QUERY } from '@/domain/category/display/queries/query';
import { DisplayCategory } from '@/common/model/Category';

export class DisplayCategoryService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async getDisplayCategories(
    param: CategoryRequestPram
  ): Promise<DisplayCategoryConnection> {
    const { page, size, filter } = param;
    const response = await this.apollo.query({
      query: DISPLAY_CATEGORY_LIST_QUERY,
      variables: { page, size, filter, fetchPolicy: 'no-cache' },
    });

    console.log('response : ', response.data.category.displays);

    return response.data.category.displays;
  }

  async createDisplayCategory(
    param: DisplayCategoryCreateParam
  ): Promise<DisplayCategory> {
    const response = await this.apollo.mutate({
      mutation: CREATE_DISPLAY_CATEGORY_MUTATION,
      variables: {
        param: param,
      },
    });

    return response.data.createDisplayCategory;
  }

  async activateDisplayCategory(id: number): Promise<void> {
    await this.apollo.mutate({
      mutation: ACTIVATE_DISPLAY_CATEGORY_MUTATION,
      variables: {
        displayCategoryId: id,
      },
    });
  }

  async inactivateDisplayCategory(id: number): Promise<void> {
    await this.apollo.mutate({
      mutation: INACTIVATE_DISPLAY_CATEGORY_MUTATION,
      variables: {
        displayCategoryId: id,
      },
    });
  }

  async moveDisplayCategory(param: MoveDisplayCategoryParam): Promise<string> {
    const response = await this.apollo.mutate({
      mutation: MOVE_DISPLAY_CATEGORY_MUTATION,
      variables: {
        param: param,
      },
    });

    return response.data.moveDisplayCategory.id;
  }

  async connectBetweenCategory(param: CategoryMapParam): Promise<void> {
    const response = await this.apollo.mutate({
      mutation: CONNECT_BETWEEN_CATEGORY,
      variables: {
        param,
      },
    });

    console.log(response);
  }
}
