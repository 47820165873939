/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-boost';
import { StandardCategoryCreateParam } from '@/domain/category/standard/model/StandardCategoryGraphqlType';
import {
  ACTIVATE_STANDARD_CATEGORY_MUTATION,
  CREATE_STANDARD_CATEGORY_MUTATION,
  INACTIVATE_STANDARD_CATEGORY_MUTATION,
} from '@/domain/category/standard/queries/mutation';
import { StandardCategory } from '@/common/model/Category';

export class StandardCategoryService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async createStandardCategory(
    param: StandardCategoryCreateParam
  ): Promise<StandardCategory> {
    // eslint-disable-next-line no-useless-catch
    try {
      const result = await this.apollo.mutate({
        mutation: CREATE_STANDARD_CATEGORY_MUTATION,
        variables: {
          param: param,
        },
      });

      return result.data.createStandardCategory;
    } catch (e) {
      throw e;
    }
  }

  async activateStandardCategory(id: string) {
    // eslint-disable-next-line no-useless-catch
    try {
      await this.apollo.mutate({
        mutation: ACTIVATE_STANDARD_CATEGORY_MUTATION,
        variables: {
          standardCategoryId: id,
        },
      });
    } catch (e) {
      throw e;
    }
  }

  async inactivateStandardCategory(id: string) {
    // eslint-disable-next-line no-useless-catch
    try {
      await this.apollo.mutate({
        mutation: INACTIVATE_STANDARD_CATEGORY_MUTATION,
        variables: {
          standardCategoryId: id,
        },
      });
    } catch (e) {
      throw e;
    }
  }
}
