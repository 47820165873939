





































































import Vue from 'vue';
import { apolloClient } from '@/apolloClient';
import { CategoryLabelService } from '@/domain/category/label/service/CategoryLabelService';
import { CATEGORY_LABEL_LIST_BY_LIKE_NAME_QUERY } from '@/domain/category/label/queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { CategoryLabelConnection } from '@/domain/category/label/model/CategoryLabelGraphqlType';
import { CategoryLabel } from '@/domain/category/label/model/CategoryLabel';
import { CategoryLabelFilter } from '@/domain/category/label/model/CategoryLabelFilter';
import { DisplayCategoryService } from '@/domain/category/display/service/DisplayCategoryService';
import { StandardCategoryService } from '@/domain/category/standard/service/StandardCategoryService';
import { CategoryType } from '@/domain/category/model/CategoryType';
import { StandardCategory } from '@/common/model/Category';

const categoryLabelService = new CategoryLabelService(apolloClient);
const displayCategoryService = new DisplayCategoryService(apolloClient);
const standardCategoryService = new StandardCategoryService(apolloClient);

interface CategoryLabelListParam {
  page?: number;
  size?: number;
  filter?: CategoryLabelFilter;
}

export default Vue.extend({
  name: 'CategoryLabelCreate',
  props: {
    parentCategoryId: {
      type: String,
    },
    categoryType: {
      type: String,
    },
    depth: {
      type: Number,
    },
  },
  data() {
    return {
      code: '',
      name: '',
      showErrorMessageTime: 0,
      errorMessage: '',
      page: 1,
      pageSize: 10,
      searchName: '',
      responseCategoryLabels: { edges: [] } as CategoryLabelConnection,
      searchedCategoryLabels: [] as CategoryLabel[],
      areaUnrelated: false,
      pathName: '',
      disableAddButton: false,
    };
  },
  computed: {
    existentSearchedCategoryLabels(): boolean {
      console.log(this.searchedCategoryLabels.length);

      const duplicationCategoryLabel = this.searchedCategoryLabels.find(
        categoryLabel => categoryLabel.name === this.name
      );

      return duplicationCategoryLabel === undefined;
    },
  },
  created() {
    this.pathName = this.$route.fullPath.split('/')[2];
    console.log(this.pathName);
  },
  methods: {
    async createCategoryLabel() {
      this.disableAddButton = true;
      let response;
      this.validateCode(this.code);
      console.log('this.code : ', this.code);
      console.log('this.name : ', this.name);

      try {
        const categoryLabel = await categoryLabelService.createCategoryLabel({
          code: this.code,
          name: this.name,
        });

        console.log(categoryLabel);

        const categoryLabelCode = categoryLabel.code;

        if (this.categoryType === CategoryType.DISPLAY) {
          response = await displayCategoryService.createDisplayCategory({
            parentId: Number(this.parentCategoryId),
            categoryLabelCode,
            areaUnrelated: this.areaUnrelated,
          });
        } else {
          let parentId = this.parentCategoryId;
          response = [];
          for (let i = this.depth; i <= 4; i++) {
            const standardCategory =
              await standardCategoryService.createStandardCategory({
                parentId,
                categoryLabelCode,
              });
            parentId = standardCategory.id;
            response.push(standardCategory);
          }
        }

        this.$notify({
          title: '카테고리 라벨 등록 성공',
          message: '카테고리 라벨이 등록되었습니다.',
          type: 'success',
          position: 'bottom-right',
        });

        this.code = '';
        this.name = '';
        console.log(response);
        // this.$router.go(0);
        this.$emit('close', response);
      } catch (e) {
        console.error(e);
        this.showErrorMessage(e.message);
        this.$notify({
          title: '카테고리 라벨 등록 실패',
          message: e.message.replace('GraphQL error:', ''),
          type: 'warning',
          position: 'bottom-right',
        });
        this.disableAddButton = false;
        // throw e;
      }
    },

    validateCode(code: string) {
      const categoryLabelCodeReg = new RegExp(`^[a-z]+$`);

      if (!categoryLabelCodeReg.test(code)) {
        this.disableAddButton = false;
        this.showErrorMessage('카테고리 라벨 코드는 영문 소문자만 가능합니다.');
        throw new Error(`카테고리 라벨 코드는 영문 소문자만 가능합니다.`);
      }
    },

    showErrorMessage(message: string): void {
      console.log(message);
      this.showErrorMessageTime = 5;
      this.errorMessage = message.replace('GraphQL error:', '');
    },

    countDownChanged(showErrorMessageTime: number): void {
      this.showErrorMessageTime = showErrorMessageTime;
    },
    searchCategoryLabelByName(inputName: string): void {
      console.log('inputName : ', inputName);
      this.searchName = inputName;
      this.$emit('searchCategoryLabelName', inputName);
    },
  },
  apollo: {
    getCategoryLabels: {
      query: CATEGORY_LABEL_LIST_BY_LIKE_NAME_QUERY,
      variables(): CategoryLabelListParam {
        return {
          page: this.page,
          size: this.pageSize,
          filter: {
            nameLike: this.searchName,
          },
        };
      },
      error(e: ApolloError): void {
        console.error(e);
      },
      skip(): boolean {
        return this.searchName === '';
      },
      result(
        result: ApolloQueryResult<{
          category: { labels: CategoryLabelConnection };
        }>
      ) {
        this.$data.responseCategoryLabels = result.data.category.labels;

        this.$data.searchedCategoryLabels =
          result.data?.category.labels.edges.map(edge => {
            return {
              id: edge.node.id,
              code: edge.node.code,
              name: edge.node.name,
            } as CategoryLabel;
          });

        this.$emit('searchCategoryLabels', this.$data.searchedCategoryLabels);
      },
      update: data => {
        data?.category.labels;
      },
      debounce: 200,
    },
  },
});
