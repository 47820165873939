import gql from 'graphql-tag';

export const CREATE_STANDARD_CATEGORY_MUTATION = gql`
  mutation createStandardCategory($param: StandardCategoryCreateParam!) {
    createStandardCategory(param: $param) {
      id
      label {
        code
        name
      }
      depth
      seqNo
      status
    }
  }
`;

export const ACTIVATE_STANDARD_CATEGORY_MUTATION = gql`
  mutation activateStandardCategory($standardCategoryId: String!) {
    activateStandardCategory(standardCategoryId: $standardCategoryId) {
      id
    }
  }
`;

export const INACTIVATE_STANDARD_CATEGORY_MUTATION = gql`
  mutation inactivateStandardCategory($standardCategoryId: String!) {
    inactivateStandardCategory(standardCategoryId: $standardCategoryId) {
      id
    }
  }
`;
