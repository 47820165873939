import gql from 'graphql-tag';

export const CREATE_DISPLAY_CATEGORY_MUTATION = gql`
  mutation createDisplayCategory($param: DisplayCategoryCreateParam!) {
    createDisplayCategory(param: $param) {
      id
      label {
        code
        name
      }
      depth
      seqNo
      status
      areaUnrelated
      relatedStandards {
        id
        label {
          id
          code
          name
        }
        status
      }
    }
  }
`;

export const ACTIVATE_DISPLAY_CATEGORY_MUTATION = gql`
  mutation activateDisplayCategory($displayCategoryId: Int!) {
    activateDisplayCategory(displayCategoryId: $displayCategoryId) {
      id
    }
  }
`;

export const INACTIVATE_DISPLAY_CATEGORY_MUTATION = gql`
  mutation inactivateDisplayCategory($displayCategoryId: Int!) {
    inactivateDisplayCategory(displayCategoryId: $displayCategoryId) {
      id
    }
  }
`;

export const MOVE_DISPLAY_CATEGORY_MUTATION = gql`
  mutation moveDisplayCategory($param: MoveDisplayCategoryParam!) {
    moveDisplayCategory(param: $param) {
      id
    }
  }
`;

export const CONNECT_BETWEEN_CATEGORY = gql`
  mutation connectBetweenCategory($param: CategoryMapParam!) {
    connectBetweenCategory(param: $param) {
      standardCategory {
        id
      }
    }
  }
`;
