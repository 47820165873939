import gql from 'graphql-tag';

export const CREATE_CATEGORY_LABEL_MUTATION = gql`
  mutation createCategoryLabel($param: CategoryLabelCreateParam!) {
    createCategoryLabel(param: $param) {
      id
      code
      name
    }
  }
`;
