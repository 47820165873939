import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-boost';
import { CategoryLabelCreateParam } from '@/domain/category/label/model/CategoryLabelGraphqlType';
import { CREATE_CATEGORY_LABEL_MUTATION } from '@/domain/category/label/queries/mutation';
import { CategoryLabel } from '@/common/model/Category';

export class CategoryLabelService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async createCategoryLabel(
    param: CategoryLabelCreateParam
  ): Promise<CategoryLabel> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.apollo.mutate({
        mutation: CREATE_CATEGORY_LABEL_MUTATION,
        variables: {
          param: param,
        },
      });

      return response.data.createCategoryLabel;
    } catch (e) {
      throw e;
    }
  }
}
