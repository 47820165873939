import gql from 'graphql-tag';

export const CATEGORY_LABEL_LIST_BY_LIKE_NAME_QUERY = gql`
  query categoryLabels($page: Int, $size: Int, $filter: CategoryLabelFilter) {
    category {
      labels(page: $page, size: $size, filter: $filter) {
        edges {
          node {
            id
            code
            name
          }
        }
      }
    }
  }
`;
