import gql from 'graphql-tag';

export const DISPLAY_AREA_RELATED_FRAGMENT = gql`
  fragment displayRelatedStandards on DisplayCategory {
    relatedStandards {
      id
      label {
        id
        code
        name
      }
      status
    }
  }
`;

export const DISPLAY_CATEGORY_LIST_QUERY = gql`
  query displayCategories(
    $filter: DisplayCategoryFilter
    $page: Int
    $size: Int
  ) {
    category {
      displays(page: $page, size: $size, filter: $filter) {
        totalCount
        edges {
          node {
            id
            label {
              id
              code
              name
            }
            ...displayRelatedStandards
            areaUnrelated
            status
            depth
            seqNo
            children {
              id
              status
              ...displayRelatedStandards
              label {
                id
                code
                name
              }
              areaUnrelated
              depth
              seqNo
              children {
                id
                status
                ...displayRelatedStandards
                label {
                  id
                  code
                  name
                }
                areaUnrelated
                depth
                seqNo
                children {
                  id
                  status
                  ...displayRelatedStandards
                  label {
                    id
                    code
                    name
                  }
                  areaUnrelated
                  depth
                  seqNo
                }
              }
            }
          }
        }
      }
    }
  }
  ${DISPLAY_AREA_RELATED_FRAGMENT}
`;

export const DISPLAY_CATEGORY_LEAVES_QUERY = gql`
  query getLeafCategories(
    $filter: LeafCategoryFilter
    $page: Int
    $pageSize: Int
  ) {
    category {
      displayLeaves(page: $page, size: $pageSize, filter: $filter) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            depth
            label {
              id
              code
              name
            }
            parent {
              id
              depth
              label {
                id
                code
                name
              }
              parent {
                id
                depth
                label {
                  id
                  code
                  name
                }
                parent {
                  id
                  depth
                  label {
                    id
                    code
                    name
                  }
                  parent {
                    id
                    depth
                    label {
                      id
                      code
                      name
                    }
                    parent {
                      id
                      depth
                      label {
                        id
                        code
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
